@font-face {
  font-family: Grenadier;
  src: url(../public/GrenadierNF.ttf);
}

@font-face {
  font-family: Seaside;
  src: url(../public/SeasideResortNF.ttf);
}

@font-face {
  font-family: Milton;
  src: url(../public/Milton_Two.otf);
}

@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@200;400&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Qwigley&display=swap');

:root {
  --bg: rgb(2, 2, 10);
  --bgRGB: 2, 2, 10;
  --font: #CCDBDC;
  --accents: #05204A;
  --spec1: #F4A261;
  --spec2: #F72585;
  --spec3: #256EFF;
  --spec4: #F45B69;
  --spec5: #CEFF1A;
  /* --bg: rgb(255, 252, 245);
  --accents: whitesmoke;
  --font: black; */
}


body {
  margin: 0;
  /* font-family: Grenadier; */
  font-family: 'Poppins', sans-serif;
  /* background-image: url("../public/img/bg.jpeg"); */
  background-color: var(--bg);
  color: var(--font);
}

body::-webkit-scrollbar {
  display: none;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  /* font-family: Seaside; */
  font-family: 'Poppins', sans-serif;
}

button {
  /* font-family: Seaside; */
  font-family: 'Poppins', sans-serif;
}

input,
textarea {
  /* font-family: Grenadier; */
  font-family: 'Poppins', sans-serif;
  letter-spacing: 0.2rem;
}

.special {
  font-family: 'Qwigley', cursive;
}

.container {
  width: 80vw;
  margin: 0 10vw;
}