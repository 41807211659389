.Placeholder {
    transform: translate(-50%, -50%);
    width: 50px;
    height: 50px;
    border-radius: 50%;
    border: 4px solid transparent;
    border-top-color: var(--spec1);
    animation: spin 2s linear infinite, changeColor 4s ease-in-out infinite;

}

@keyframes spin {
    to {
        transform: translate(-50%, -50%) rotate(360deg);
    }
}

@keyframes changeColor {
    0% {
        border-top-color: var(--spec1);
    }

    20% {
        border-top-color: var(--spec2);
    }

    40% {
        border-top-color: var(--spec3);
    }

    60% {
        border-top-color: var(--spec4);
    }

    80% {
        border-top-color: var(--spec5);
    }

    100% {
        border-top-color: var(--spec1);
    }
}