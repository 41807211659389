.home {
    .hero {
        position: relative;
        height: fit-content;
        height: 400px;
        width: 100vw;
        overflow-y: hidden;

        img {
            height: 100%;
            width: 100%;
            object-fit: cover;
            object-position: 50% center;
            filter: blur(1.5px);

            position: absolute;

            animation: gal 12s cubic-bezier(0.68, -0.55, 0.27, 1.55) infinite;

            &:nth-of-type(1) {
                animation-delay: 0s;
            }

            &:nth-of-type(2) {
                animation-delay: -4s;
            }

            &:nth-of-type(3) {
                animation-delay: -8s;
            }

            @keyframes gal {
                0% {
                    transform: translateY(100%);
                }

                50% {
                    transform: translateY(0);
                }

                100% {
                    transform: translateY(-100%);
                }
            }

        }

        h1 {
            position: absolute;
            margin: 0;
            width: 100%;
            height: 100%;
            top: 0;
            display: flex;
            flex-direction: column;
            justify-content: center;
            text-align: center;
            display: flex;
            flex-direction: column;
            align-items: center;

            span {
                font-size: 3rem;
                font-weight: 100;
                letter-spacing: 0.4rem;
                background-color: var(--bg);
                color: white;
                text-align: center;


                width: 90vw;
                height: 1px;


                // background-image: url(https://firebasestorage.googleapis.com/v0/b/anis-jerbi-art-website.appspot.com/o/img%2FOfficial%20photo%20of%20oil%20painting%20of%20Taylor%20Swift.jpg?alt=media&token=07782c3e-d5eb-4e5f-b29f-ddb1995a82b9);
                background-size: cover;
                background-position: center;
                background-blend-mode: luminosity;
                box-shadow: inset 0 0 4px 1px white, 0 0 4px 0px white;

                $timing: cubic-bezier(0.19, 1, 0.22, 1);
                $dur: 0.75s;
                transition: height $dur $timing,
                    width $dur $timing;


                display: flex;
                justify-content: center;
                align-items: center;

                font {
                    transform: translateY(-25px);
                    white-space: nowrap;
                    overflow: hidden;
                }

                &:hover {
                    padding: 0;
                    height: 90%;
                    width: 100%;

                    font {
                        transform: translateY(0);
                    }
                }

            }

            #sub {
                font-size: 3rem;
            }
        }
    }

    .subnav {
        border-bottom: 1px solid;
        padding: 10px 0 15px 0;

        nav {
            display: flex;
            justify-content: space-evenly;
            align-items: center;

            a {
                all: unset;
                cursor: pointer;
                display: flex;
                align-items: center;
                flex-direction: column;

                hr {
                    margin: 0;
                    border: none;
                    height: 1px;
                    background-color: transparent;
                    width: 5%;
                    transition: all 1s cubic-bezier(0.075, 0.82, 0.165, 1);
                }

                &:hover {
                    hr {
                        background-color: var(--font);
                        width: 100%;
                    }
                }
            }
        }
    }

    .cardsection {
        display: flex;
        align-items: stretch;
        gap: 8rem;
        margin: 50px 0;

        .img {
            border: 4px solid;
            width: 40%;
            box-sizing: content-box;
            padding: 10px;
            display: flex;
            justify-content: center;
            align-items: center;
            background-color: white;

            img {
                height: 100%;
                width: 100%;
                object-fit: cover;
            }
        }
    }

    .donations {
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 2rem;

        a {
            text-decoration: none;
        }

        button {
            cursor: pointer;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            gap: 0.5rem;
            background-color: var(--bg);
            border: 2px solid transparent;
            padding: 15px;
            color: var(--font);

            svg {
                fill: var(--font);
                width: 30px;
            }

            transition: all 300ms;

            &:hover {
                border-color: var(--accents);
            }
        }
    }
}




@media screen and (width <=1200px) {
    .home {

        .cardsection {
            gap: 1rem;
            flex-direction: column;

            .img {
                width: 90%;
            }
        }
    }
}

@media screen and (width <=1000px) {
    .home {
        .hero {
            #title {
                font-size: 2rem;
                // padding-top: 10px;
            }
        }
    }
}

@media screen and (width <=768px) {
    .home {
        .hero {
            #title {
                font-size: 3rem;

                font {
                    white-space: normal;
                    transform: translateY(0px);

                }
            }
        }
    }
}


.cookieConsent {
    background-color: rgba(var(--bgRGB), 0.8) !important;
}