.press {
    .title {
        width: 80vw;
        margin: 50px 10vw;
        text-align: center;

        h1 {
            color: white;
            font-size: 1.5rem;
        }
    }

    .articles{
        display: grid;
        grid-template-columns: repeat(3, 1fr);
    }
}

@media screen and (width <= 1000px) {
    .press{
        .articles{
            display: grid;
            grid-template-columns: repeat(2, 1fr);
        }
    }
}

@media screen and (width <= 768px) {
    .press{
        .articles{
            display: grid;
            grid-template-columns: repeat(1, 1fr);
        }
    }
}