.animated-image {
    transition: all 500ms cubic-bezier(0.175, 0.885, 0.32, 1.275);
    opacity: 0;
    filter: blur(50px);
    scale: 0;
    overflow: hidden;

    &.visible {
        filter: blur(0px);
        opacity: 1;
        scale: 1;
    }
}