.morecoming {
    display: flex;
    justify-content: center;
    align-items: center;
}

.gallery {
    .loadingwrapper {
        width: 100%;
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .image {
        position: relative;
        width: 100vw;

        img {
            width: 100vw;
            height: auto;
            transition: all 300ms;
        }

        .title {
            transition: all 300ms;
            position: absolute;
            color: white;
            z-index: 1;
            bottom: 0;
            padding-bottom: 20px;
            width: 100vw;
            text-align: center;
            font-size: 1.25rem;
            opacity: 0;
        }

        .ribbon {
            background-color: rgba(255, 0, 0, 0.534);
            position: absolute;
            z-index: 1;
            top: 0;
            right: 0;
            color: aliceblue;
            padding: 5px;
            font-size: 0.9rem;
        }

        .imgid {
            position: absolute;
            background-color: red;
            padding: 2px;
            z-index: 1;
            bottom: 0;
            right: 0;
        }
    }

    .image-selected {
        img {
            opacity: 0.3;
        }

        .title {
            opacity: 1;
        }
    }
}

@media screen and (min-width:821px) {
    .gallery {
        display: flex;
        flex-wrap: wrap;
        margin: 5vh 8vw;
        align-items: center;
        justify-content: center;
        gap: 1rem;
        row-gap: 2rem;

        .image {
            width: 20vw;

            img {
                width: 20vw !important;
            }

            .title {
                width: 20vw !important;
            }

        }

        .image:hover {
            img {
                opacity: 0.3;
            }

            .title {
                opacity: 1;
            }
        }

        .image-selected {
            z-index: 999;
            position: fixed;
            top: 0;
            height: 100vh !important;
            width: 100vw !important;
            background-color: rgba(0, 0, 0, 0.966);
            display: flex;
            justify-content: center;
            align-items: center;

            img {
                opacity: 1 !important;
                height: 90vh;
                width: auto !important;
            }

            .title {
                opacity: 0 !important;
            }
        }
    }
}

@media screen and (width <=600px) {
    .gallery {
        .image {
            margin-bottom: 20px;
        }
    }
}