.contacts {
    width: 80vw;
    margin: 14vh 10vw;
    min-height: 55vh;

    .fstrow {
        display: flex;
        align-items: center;
        gap: 3rem;
        width: 100%;

        img {
            width: 50%;
            max-height: 750px;
            object-fit: cover;
        }

        .formcontact {
            width: 50%;

            h1 {
                color: white;
                text-align: center;
                font-size: 1.4rem;
            }

            div {
                display: flex;
                justify-content: center;
                align-items: center;
            }

            input {
                border: 2px solid white;
                background-color: black;
                width: 100%;
                max-width: 600px;
                font-size: 1.1rem;
                color: white;
                padding: 5px 0 5px 5px;
                margin-bottom: 20px;
            }

            textarea {
                width: 100%;
                max-width: 600px;
                font-size: 1.1rem;
                overflow: hidden;
                background-color: black;
                border: 2px solid white;
                padding: 5px 0 0 5px;
                color: white;
            }

            .button-wrapper {
                margin-top: 30px;
                width: 100%;
                display: flex;
                justify-content: center;
                align-items: center;

                button {
                    padding: 20px 40px;
                    font-size: 1.1rem;
                    border: 2px solid transparent;
                    transition: all 250ms;
                    cursor: pointer;

                    &:hover {
                        background-color: var(--font);
                    }
                }

                button:active {
                    color: white;
                    background-color: black;
                    border: 2px solid white;

                }
            }
        }

    }

    .more {
        margin-top: 50px;

        a,
        ul {
            all: unset;
            color: var(--spec3);
            font-size: 1.2rem;
            cursor: pointer;
        }

        li {
            margin-bottom: 20px;
        }

        a {
            transition: all 200ms ease-in-out;

            &:hover {
                color: var(--font);
            }
        }
    }
}

@media screen and (min-width:2000px) {
    .contacts {
        margin: 20vh 10vw;
    }
}

@media screen and (width <=900px) {
    .contacts {
        .fstrow {
            position: relative;
            img {
                // display: none;
                position: absolute;
                height: 100%;
                width: 100%;
                z-index: -1;
                pointer-events: none;                
            }

            .formcontact {
                width: 100% !important;
                padding: 20px;
                h1{
                    color:  rgba(0, 0, 0, 0.801);
                }
                input,textarea{
                    background-color: rgba(0, 0, 0, 0.801);
                }
            }
        }
    }
}