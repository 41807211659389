.Admin {
    form {
        display: flex;
        flex-direction: column;
        width: 80vw;
        margin: 0 10vw;
        gap: 0.4rem;
        margin-bottom: 40px;

        h2 {
            margin-bottom: 10px;
        }

        input[type="text"] {
            width: 100%;
        }

        div:last-of-type {
            text-align: center;

            button {
                width: 100%;
                background-color: var(--accents);
                color: white;
                border: 1px solid white;
                padding: 8px 20px;
                cursor: pointer;
                transition: scale 200ms cubic-bezier(0.55, 0.055, 0.675, 0.19);

                &:hover {
                    scale: 1.1;
                }

                &#logout {
                    background-color: crimson;
                }
            }
        }

    }
}