.footer {
    background-image: url(../../../public/img/BGfooter.jpeg);
    background-position-y: -10px;
    background-size: 90%;
    width: 100vw;
    padding: 20px 0;
    padding-bottom: 5px;
    margin-top: 30px;

    .rowSocials {
        width: 100vw;
        display: flex;
        justify-content: space-around;

        div {
            svg {
                width: 30px;
                fill: white;
            }
        }
    }

    .rowMail {
        margin-top: 20px;
        text-align: center;
        color: white;

        a {
            text-decoration: none;
            color: white;
            font-size: 1.2rem;
        }

        div {
            margin-top: 20px;
        }
    }

    .dev {
        padding-right: 20px;
        text-align: end;
        margin-top: 30px;

        svg {
            height: 50px;
            transition: all 500ms;

            * {
                transition: all 500ms;
            }

            &:hover {
                fill: orange;

                * {
                    fill: orange;

                }
            }
        }
    }

}