.header {
    background-color: var(--accents);

    a {
        text-decoration: none;
        color: var(--font);
        font-size: 18px;
        font-weight: 200;
        transition: all 250ms cubic-bezier(0.25, 0.46, 0.45, 0.94);
    }

    .selected-link {
        a {
            scale: 1.1;            
            color: var(--spec1);
        }
    }
}

@media only screen and (max-width: 600px) {
    .header {
        position: relative;
        height: 80px;
        z-index: 999;

        img {
            height: auto;
            width: 40vw;
            max-width: 251px;
        }

        .desktop-menu {
            .link {
                display: none;
            }
            .img-wrapper{
                display: block;
            }
        }

        .img-wrapper {
            text-align: center;
        }

        .burger-menu {
            position: absolute;
            top: 25%;
            padding-left: 20px;

            svg {
                width: 20px;
                fill: white;
            }

            .menu {
                background-color: var(--bg);
                position: absolute;
                left: -100vw;
                width: 100vw;
                height: 100vh;
                top: 60px;

                a {
                    text-decoration: none;
                    color: var(--font);
                    display: flex;
                    justify-content: center;
                }

                .link {
                    margin: 10px 0;
                    padding: 17% 0;
                    text-align: center;
                }

                transition: all 450ms;
            }

            .menu-showing {
                left: 0;
            }

        }
    }

}

@media only screen and (max-width: 400px) {
    .header {

        .menu {
            .link {
                padding: 10% 0 !important;
            }
        }
    }
}


@media only screen and (min-width: 601px) {
    .header {
        .burger-menu {
            display: none;
        }

        .desktop-menu {
            display: flex;
            justify-content: space-around;
            align-items: center;
            padding: 10px 0;

            img {
                width: 150px;
                // filter: invert(100%);
            }

            .right {
                display: flex;
                gap: 2rem;
            }

            .link {
                a {
                    display: flex;
                    justify-content: center;
                }

                h1 {
                    font-size: 1.1rem;
                    margin: 0;
                    width: fit-content;
                    font-weight: 200;
                }
            }
        }
    }
}

@media only screen and (min-width: 601px) and (max-width:860px) {
    .header {
        .desktop-menu {
            gap: 2rem !important;

            img {
                width: 140px;
            }

            .link {
                h1 {
                    font-size: 1rem !important;
                }
            }
        }
    }
}

@media only screen and (min-width: 460px) and (max-width:599px) {
    .header {
        img {
            width: 150px;
        }
    }
}