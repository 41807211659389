.Card {
    border: 6px solid;
    padding: 25px 10px;
    width: 60%;
    background-color: var(--accents);
    display: flex;
    flex-direction: column;
    justify-content: center;

    .title {
        font-size: 3.5rem;
        transform: translateX(-80px);
    }

    &:hover{
        border-opacity:0.4;
    }
}


.animated-paragraph {
    opacity: 0;
    transform: translateY(20px);
    transition: opacity 0.5s ease, transform 0.5s ease;
}

.animated-paragraph.visible {
    opacity: 1;
    transform: translateY(0);
}


@media screen and (width <=1200px) {
    .Card {
        width: 90%;
    }
}

@media screen and (width <=768px) {
    .Card {
        .title {
            transform: translateX(0);
        }
    }
}